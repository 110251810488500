import React from 'react';
import Slider from 'react-slick'; // Import React Slick component
import 'slick-carousel/slick/slick.css'; // Slick core CSS
import 'slick-carousel/slick/slick-theme.css'; // Slick theme CSS
import moviesN from '../../images/moviesN.png';
import ekommerceNextjsN from '../../images/ekommerce-nextjsN.png';
import treningN from '../../images/treningN.jpeg';
import gpbmN from '../../images/gpbmN.jpeg';
import restaurangN from '../../images/restaurangN.png';
import RecepiAppN from '../../images/RecepiAppN.png';
import todoN from '../../images/todoN.png';
import weatherReact from '../../images/weatherReact.png';
import PortfolioJSN from '../../images/PortfolioJSN.png';
import MovieOnlineN from '../../images/MovieOnlineN.png';
import BeautifN from '../../images/BeautifN.png';
import ECommerceShopN from '../../images/E-CommerceShopN.png';
import BuildingCommpanyN from '../../images/BuildingCommpanyN.png';
import weatherN from '../../images/weatherN.png';
import todoMySQLN from '../../images/todoMySQLN.png';
import PortfolioWPN from '../../images/PortfolioWPN.png';
import './projects.css'; // Your custom CSS

const Projects = () => {
  // Define settings for React Slick Carousel
  const settings = {
    dots: true, // Show dots below the carousel
    infinite: true, // Infinite scrolling
    speed: 500, // Transition speed in ms
    slidesToShow: 3, // Show 3 full slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto scroll slides
    autoplaySpeed: 3000, // Auto scroll every 3 seconds
    centerMode: false, // Disable centerMode to avoid partial images on sides
    responsive: [
      // Responsive settings for smaller devices
      {
        breakpoint: 1024, // Up to 1024px screen width
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Up to 600px screen width
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const projectImages = [
    { link: 'https://popcorn-app.vercel.app/', image: moviesN },
    {
      link: 'https://cars-ekommerce-nextjs.vercel.app/',
      image: ekommerceNextjsN,
    },
    { link: 'https://trenning-app.vercel.app/', image: treningN },
    { link: 'https://battery-front.onrender.com/', image: gpbmN },
    { link: 'https://tastydelights.netlify.app/', image: restaurangN },
    {
      link: 'https://recipes-app-u08.netlify.app/dashboard',
      image: RecepiAppN,
    },
    { link: 'https://rococo-starship-2faac9.netlify.app/', image: todoN },
    { link: 'https://app-for-weather-react.netlify.app/', image: weatherReact },
    { link: 'https://www.nikolovski.se/#', image: PortfolioJSN },
    { image: MovieOnlineN },
    { link: 'https://beautif.ai/', image: BeautifN },
    { link: '', image: ECommerceShopN },
    { link: 'https://www.uniquedesign.se/', image: BuildingCommpanyN },
    { link: '', image: weatherN },
    { image: todoMySQLN },
    { image: PortfolioWPN },
  ];

  return (
    <section className="projects" id="projects">
      <div className="projects-container">
        <h2 className="projects-head">
          My <span>Projects</span>
        </h2>
        <div className="text-animate">
          <h3>My last projects</h3>
        </div>
      </div>
      <div className="hero">
        <Slider {...settings}>
          {projectImages.map((project, index) => (
            <div key={index} className="carousel-item">
              {project.link ? (
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={project.image} alt={`Project ${index + 1}`} />
                </a>
              ) : (
                <img src={project.image} alt={`Project ${index + 1}`} />
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Projects;
