import React from 'react';
import AboutPic from '../../images/vanvanvanNev-removebgN.png';
import './about.css';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="profile-img">
        <img src={AboutPic} alt="Profile" id="about-profile-pic" />
      </div>
      <div className="about-container">
        <h2>
          About <span>Me</span>
        </h2>

        <div className="text-animate">
          <h3>Full Stack Developer / DevOps Engineer</h3>
        </div>

        <div className="social-media">
          <p>
            My name is Vande Nikolovski. I am a passionate and professional{' '}
            <span> Full Stack Developer</span> and <span>DevOps Engineer</span>{' '}
            with over 2 years of commercial experience. I specialize in creating
            high-standard, modern, fast, and user-friendly websites while also
            offering end-to-end DevOps solutions. I can help you build your
            dream website or optimize your development and deployment pipelines
            to meet your specific business requirements. My expertise spans
            front-end and back-end development, complemented by a strong passion
            for design and a deep understanding of automation, infrastructure,
            and deployment practices.
          </p>
          <p>
            I produce usable and highly scalable web and DevOps software
            solutions. With strong interpersonal skills and a proven track
            record of communicating effectively with senior executives and
            stakeholders, I am punctual, reliable, and committed to excellence.
            I excel in implementing continuous-integration and
            continuous-delivery (CI/CD) pipelines, containerization with Docker,
            and infrastructure automation with tools like Terraform and Ansible.
            My Agile approach allows me to quickly and efficiently integrate
            into teams, delivering solutions that ensure performance standards
            are consistently exceeded.
          </p>
        </div>
        <div>
          <a
            href="CV/Vande Nikolovski CV.pdf"
            className="about-btn-cv"
            download
          >
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
