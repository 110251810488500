import React from 'react';
import './home.css';
import ProfileImg from '../../images/me-removebgN.png';

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="home-container">
        <h2>Hi, my name is</h2>
        <h1>Vande Nikolovski</h1>
        <div className="text-animate">
          <h3>Full Stack Developer / DevOps Engineer</h3>
        </div>

        <div className="social-media">
          <a
            href="https://www.facebook.com/vandenikolovski"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/vande-nikolovski-054213176/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
          <a
            href="https://twitter.com/SmekiB"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/smekiboy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-instagram-alt"></i>
          </a>
        </div>
        <a href="https://example.com" className="btn-cv">
          Hire Me
        </a>
      </div>
      <div className="profile-img">
        <img src={ProfileImg} alt="Profile" id="profile-pic" />
      </div>
    </section>
  );
};

export default Home;
