import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
  // State to manage form input values
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  // State to display the result message
  const [result, setResult] = useState('');
  const [statusClassName, setStatusClassName] = useState('text-gray-500');

  // Handle form data changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setResult('Please wait...');
    setStatusClassName('text-gray-500');

    const json = JSON.stringify({
      ...formData,
      access_key: '2acc5562-896a-4839-b642-af61a48d5863',
      subject: formData.subject, // Use the subject provided by the user
      from_name: formData.name, // Use sender's name
      from_email: formData.email, // Use sender's email address
      reply_to: formData.email, // Set the reply-to address to sender's email
    });

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: json,
      });

      const responseData = await response.json();
      if (response.status === 200) {
        setResult(`Your message has been sent successfully.`);
        setStatusClassName('text-green-500');
      } else {
        setResult(responseData.message);
        setStatusClassName('text-red-500');
      }
    } catch (error) {
      console.error(error);
      setResult('Something went wrong!');
      setStatusClassName('text-red-500');
    } finally {
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });

      setTimeout(() => {
        setResult('');
      }, 5000);
    }
  };

  return (
    <section className="contact" id="contact">
      <div className="contact-container-title">
        <h2 className="head">
          Contact <span>Me</span>
        </h2>
        <div className="text-animate">
          <h3>get in touch</h3>
        </div>
      </div>

      <div className="contac-container">
        <div className="info-form-container">
          {/* Info about you */}
          <div className="row">
            <span>
              <i className="bx bxs-user"></i>
            </span>
            <div className="info">
              <div className="contact-info-name">Name</div>
              <div className="sub-title">Vande Nikolovski</div>
            </div>
          </div>
          <div className="row">
            <span>
              <i className="bx bxs-phone"></i>
            </span>
            <div className="info">
              <div className="contact-info-name">Mobile</div>
              <div className="sub-title">+46700484114</div>
            </div>
          </div>
          <div className="row">
            <span>
              <i className="bx bxs-map"></i>
            </span>
            <div className="info">
              <div className="contact-info-name">Address</div>
              <div className="sub-title">
                Kopparvägen 25, 17672 Järfälla, <br /> Stockholm, Sweden
              </div>
            </div>
          </div>
          <div className="row">
            <span>
              <i className="bx bxs-envelope"></i>
            </span>
            <div className="info">
              <div className="contact-info-name">Email</div>
              <div className="sub-title">info@nikolovski.se</div>
            </div>
          </div>
        </div>

        <div className="contact-form-container">
          <form id="form" onSubmit={handleSubmit}>
            <input
              type="hidden"
              name="access_key"
              value="2acc5562-896a-4839-b642-af61a48d5863"
            />
            <input
              type="checkbox"
              name="botcheck"
              id=""
              style={{ display: 'none' }}
            />

            <div className="input-box">
              <div className="input-field">
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <span className="focus"></span>
              </div>
              <div className="input-field">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <span className="focus"></span>
              </div>
            </div>

            <div className="input-box">
              <div className="input-field">
                <input
                  type="number"
                  name="phone"
                  placeholder="Mobile Number"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <span className="focus"></span>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  name="subject"
                  placeholder="Email Subject"
                  id="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
                <span className="focus"></span>
              </div>
            </div>

            <div className="textarea-field">
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <span className="focus"></span>
            </div>

            <div className="btn-box btns">
              <button className="btn-cv" id="btn-submit" type="submit">
                Submit
              </button>
            </div>
            <br />
            <p
              className={`text-base text-center ${statusClassName}`}
              id="result"
            >
              {result}
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
