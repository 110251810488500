import React from 'react'; // <-- Import React to fix the ESLint error
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Main from './Main';

// import Main from './Main';

function App() {
  return (
    <div id="main-wrapper" className="flex flex-col min-h-screen">
      <Navbar />
      <div id="main-content" className="flex-grow">
        <Main />
      </div>
      <Footer />
    </div>
  );
}

export default App;
