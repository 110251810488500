import React from 'react';
import sampleVideo from '../../video/one.mp4';
import './services.css';

const Services = () => {
  return (
    <section className="about" id="services">
      <div className="video-container">
        <video className="video-bg" autoPlay loop muted>
          <source src={sampleVideo} type="video/mp4" />
        </video>
      </div>
    </section>
  );
};

export default Services;
