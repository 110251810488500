import React from 'react';
import './skills.css';

const Skills = () => {
  return (
    <section className="skills" id="skills">
      <div className="skills-container">
        <h2>
          My <span>Skills</span>
        </h2>

        <div className="text-animate">
          <h3>What I Know</h3>
        </div>

        <div className="social-media">
          <h4>
            My creative <span>skills</span> & <span>experiences.</span>
          </h4>
          <br />
          <p>
            I develop scalable web applications and implement efficient DevOps
            solutions, with expertise in CI/CD pipelines, workflow automation,
            and tools like Docker, Terraform, and Ansible. Skilled at aligning
            technical strategies with business goals, I excel in front-end and
            back-end development while ensuring reliability and performance. A
            dependable and proactive professional, I consistently deliver
            results through effective collaboration and an Agile approach.{' '}
          </p>
        </div>
        <div>
          <a href="https://example.com" className="skills-btn-cv">
            Read More
          </a>
        </div>
      </div>
      <div className="profile-img">
        <div className="skills-experiance">
          <div className="skills-experience-one">
            <ul>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-html5"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-css3"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-javascript"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-react"></span>
                </a>
              </li>
            </ul>
          </div>

          <div className="skills-experience-two">
            <ul>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-bootstrap"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-nodejs"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-typescript"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-docker"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="skills-experience-three">
            <ul>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-aws"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-microsoft"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-postgresql"></span>
                </a>
              </li>
              <li>
                <a href="https://example.com">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="bx bxl-gitlab"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
