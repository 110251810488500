import React, { useEffect, useRef } from 'react';
import './navbar.css';

const Navbar = () => {
  // Refs to access DOM elements
  const menuIconRef = useRef(null);
  const navbarRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    // Toggle menu function
    const handleMenuClick = () => {
      if (menuIconRef.current && navbarRef.current) {
        menuIconRef.current.classList.toggle('bx-x');
        navbarRef.current.classList.toggle('active');
      }
    };

    // Scroll behavior function
    const handleScroll = () => {
      if (headerRef.current) {
        headerRef.current.classList.toggle('sticky', window.scrollY > 100);
      }

      // Update active navigation link based on scroll position
      const sections = document.querySelectorAll('section');
      const navLinks = document.querySelectorAll('header nav a');

      sections.forEach((section) => {
        const top = window.scrollY;
        const offset = section.offsetTop - 100;
        const height = section.offsetHeight;
        const id = section.getAttribute('id');

        if (top >= offset && top < offset + height) {
          navLinks.forEach((link) => {
            link.classList.remove('active');
          });
          const activeLink = document.querySelector(
            `header nav a[href*=${id}]`
          );
          if (activeLink) activeLink.classList.add('active');
        }
      });

      // Reset the mobile menu and icon when scrolling
      if (menuIconRef.current && navbarRef.current) {
        menuIconRef.current.classList.remove('bx-x');
        navbarRef.current.classList.remove('active');
      }
    };

    // Attach event listeners
    const menuIcon = menuIconRef.current;
    if (menuIcon) {
      menuIcon.addEventListener('click', handleMenuClick);
    }
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listeners on component unmount
    return () => {
      if (menuIcon) {
        menuIcon.removeEventListener('click', handleMenuClick);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header ref={headerRef} className="header">
      <a href="#home" className="logo">
        Nikolov<span>ski.</span>
      </a>

      <div className="bx bx-menu" id="menu-icon" ref={menuIconRef}></div>

      <nav className="navbar" ref={navbarRef}>
        <a href="#home" className="active">
          Home
        </a>
        <a href="#about">About</a>
        <a href="#skills">Skills</a>
        <a href="#projects">Projects</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
        <span className="active-nav"></span>
      </nav>
    </header>
  );
};

export default Navbar;
